.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.ml-auto {
  margin-left: auto !important;
}
.mt-auto {
  margin-top: auto !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}
.app_button_bg{
  background: #099AD9
}
.y-scroll{
  height: 100vh!important;
  overflow-y: auto;
}
.h-scroll{
  width: 100vh!important;
  overflow-x: auto;
}
.ui.grid {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
    margin-left: 0rem !important;
    margin-right: 0rem !important;
}
.ui.grid>* {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
}
.disabled{
  pointer-events: none;
  opacity: 0.4;
 }

.pageHeader{
font-family: Montserrat!important;
font-style: normal!important;
font-weight: 400!important;
font-size: 16px !important;
color: #004764!important;
margin-bottom: 1rem !important;
}
.sectionHeader{
  font-family: Montserrat!important;
  font-style: normal!important;
  font-weight: 500!important;
  font-size: 18px!important;
  color: #004764!important;
  margin-bottom: 2rem !important;
}
.ui.form .field>label {
  color: #004764 !important;
}

.content-center{
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}
.content-center-h{
  display: flex;
  justify-content: center;
}
.content-center-v{
  display: flex;
  align-items: center;
}
.w-100{
  width: 100% !important;
}
.h-100{
  height: 100% !important;
}
.action-button{
  box-shadow: 0px 0px 4px rgba(102, 72, 72, 0.25)!important;
}
.action-button {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 1rem !important;
  border: 1px solid #006dff !important;
  background-color: #ffffff !important;
  border: 1px solid #006dff !important;
  border-radius: 12px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  /* padding: 1rem !important; */
  border-radius: 12px !important;
  color: #1a202c !important;
}

.action-button * {
  color: #1a202c !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.action-button:hover{
  background: #EDFAFF!important;
}
.no-p-left{
  padding-left: 0px!important;
}
.no-p-right{
  padding-right: 0px!important;
}
.no-p-top{
  padding-top: 0px!important;
}
.no-p-bottom{
  padding-bottom: 0px!important;
}
.ui.table tbody {
  display: block;
  /* max-height: 300px; */
  overflow-y: scroll;
}
.ui.table thead, .ui.table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-bottom: 0px!important;
}

.no-m-left{
  margin-left: 0px!important;
}
.no-m-right{
  margin-right: 0px!important;
}
.no-m-top{
  margin-top: 0px!important;
}
.no-m-bottom{
  margin-bottom: 0px!important;
}
.no-p{
  padding: 0px!important;
}
.no-m{
  margin: 0px!important;
}
.status {
  min-width:70px !important;
  max-width:70px !important;
  background: #ffffff;
  border: 1px solid #06990c;
  box-sizing: border-box;
  border-radius: 31px;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #06990c;
  text-align: center !important;
  padding: 5px !important;
}
.text-right{
  text-align: right!important;
}
.text-left{
  text-align: left!important;
}
.status.active {
  border: 1px solid #06990c;
  color: #06990c;
}
.status.inactive {
  border: 1px solid #C6150A;
  color: #C90C00;
}
.status.opened {
  border: 1px solid #1a8bf7;
  color: #1a8bf7;
}
.status.closed {
  border: 1px solid #f2a813;
  color: #f2a813;
}
.submitBtn {
  cursor: pointer !important;
  background: #179ed4 !important;
  border-radius: 10px !important;
  font-weight: 600 !important;
  text-align: center !important;
  font-size: 12px !important;
  color: #ffffff !important;
  opacity: 0.9 !important;
  min-height: 50px !important;
}
.thCell{
  color: #004764 !important;
  text-align: left;
  padding: 18px!important;
  font-size: 12px!important;
}
.rowCell {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px;
  color: #004764;
}
.align-center {
  padding: 0 !important;
  text-align: center !important;
}
.tb-header{
  background: #F5F5F5;
  border-radius: 6px;
}
.submitBtn:hover {
  background: #179ed4 !important;
  opacity: 1 !important;
}
.ui.table thead th {
  border-bottom: none !important;
  /* background: #F5F5F5; */
}
.datePicker {
  display: inline !important;
  padding: 0 !important;
  background: transparent !important;
  border: none !important;
}
.cardLayout {
  width: 100% !important;
  padding: 8px !important;
  background: #ffffff;
  box-shadow: 0px 3px 22px rgba(0, 0, 0, 0.1) !important;
  border-radius: 7px;
}
.options {
  box-shadow: 0px 1px 2px 1.3px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px;
  padding: 3px;
  cursor: pointer !important;
  width: fit-content !important;
  height: fit-content !important;
}
.plan {
  background: #8F00FF!important;
  text-align: center !important;
  color: #FFFFFF !important;
  padding: 5px !important;
}
.plan.annual {
  background: #3EA300!important;
}
.plan.biAnnual {
  background: #FE620A !important;
}
.plan.general {
  background: #f4d4f8!important;
}
.plan.monthly {
  background: #FE620A!important;
}
.topLayout {
  padding: 30px !important;
  border-radius: 7px !important;
  background: rgba(209, 242, 255, 0.4) !important;
  margin-bottom: 20px !important;
  width: 100% !important;
  color: #004764 !important;
}

.error-msg {
  color: red;
  margin: 8px 0;
}